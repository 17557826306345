var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"loading-text":"Cargando... Espere","headers":_vm.isActive ? _vm.activeHeaders : _vm.completedHeaders,"items":_vm.complaints,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"LT")))])]}},{key:"item.planned_time",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.planned_time,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.planned_time,"LT")))])]}},{key:"item.actual_time",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.actual_time,"LL")))]),_vm._v(" - "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.actual_time,"LT")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.isActive)?_c('v-chip',{attrs:{"color":_vm.getComplaintActionColor(item.action),"dark":""}},[_vm._v(" "+_vm._s(_vm.getComplaintActionValue(item.action))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.getIconOfComplaintAction(item.action))+" ")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","density":"compact"},on:{"click":function($event){return _vm.$emit('view-complaint', item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-message-bulleted ")])],1),(!_vm.isActive)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary","density":"compact"},on:{"click":function($event){return _vm.$emit('view-response', item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-email-sync-outline ")])],1):_vm._e(),(_vm.isActive)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","small":"","density":"compact","color":"success"},on:{"click":function($event){_vm.$emit('refund-complaint', item, _vm.complaints.indexOf(item))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cash-refund ")])],1):_vm._e(),(_vm.isActive)?_c('v-btn',{attrs:{"depressed":"","small":"","density":"compact","color":"error"},on:{"click":function($event){_vm.$emit('cancel-complaint', item, _vm.complaints.indexOf(item))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-octagon-outline ")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }